import React, { useState, useEffect } from 'react'
import { useApp } from '../useApp'
import FullTable from './FullTable'
import OrderForm from './OrderForm'
import { Grid } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ReactGA from 'react-ga4'

import Toolbar from './Toolbar'
import LoadingIndicator from '../common/LoadingIndicator'
import fetchAsync from '../backend/fetchAsync'
import i18n, { getLanguage } from '../../i18n'

const BACKEND_URL = process.env.REACT_APP_BACKEND || 'https://qr-backend-preview.asab.hpm.agency'

const Description = styled.div`
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 20px;
`

const Error = styled.div`
  color: #933;
  margin-bottom: 15px;
  margin-top: 16px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
`

const sendCartOrderAnalytics = () => {
  try {
    ReactGA.event({
      category: 'Cart',
      action: 'Order'
    })
  } catch (error) {
    console.error(`Could not send analytics event ${error.message}`)
  }
}

const Component = () => {
  const { language } = useParams()
  const { loading, documentsAll, cartLanguage, cartAmount, resetCart } = useApp()
  const [cartDocuments, setCartDocuments] = useState([])
  const [error, setError] = useState()

  const history = useHistory()

  useEffect(() => {
    setCartDocuments(
      documentsAll
        .filter((doc) => {
          return Object.keys(cartLanguage).includes(doc.id)
        })
        .map(document => {
          return {
            ...document,
            cartAmount: cartAmount[document.id],
            title: document.title[cartLanguage[document.id]],
            language: cartLanguage[document.id]
          }
        })

    )
  }, [documentsAll, cartLanguage, cartAmount])

  const handleSubmit = async (address) => {
    try {
      // eslint-disable-next-line
      const data = await fetchAsync(`${BACKEND_URL}/order`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          documents: cartDocuments.map(({ id, cartAmount: amount, language }) => {
            return { id, amount, language }
          }),
          address: {
            name: [address.firstname, address.lastname].join(' '),
            zip: address.zip,
            street: address.street,
            city: address.city,
            company: address.companyname,
            phone: address.phone,
            email: address.email,
            salutation: address.salutation,
            comment: address.comment

          },
          language: getLanguage()
        })
      }).then(function (data) {
        if (data.success) {
          sendCartOrderAnalytics()
          resetCart()
          history.push(`/cart/ordered/${language || ''}`)
        } else {
          return setError('Bitte füllen Sie alle notwendigen Felder aus. (*)')
        }
      })
    } catch (err) {
      console.log(err.message)
      setError(err.message)
    }
  }

  return (
    <>
      <Toolbar to={`/cart/${language}`} title={i18n.t('orderForm.headline')} />
      <Grid container direction='row' spacing={0}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Description> {i18n.t('orderForm.pleaseFillForm')}</Description>

          {!!error && <Error>{i18n.t('orderForm.orderFailed')}</Error>}

        </Grid>
      </Grid>
      <Grid container direction='row' spacing={0}>
        <Grid item xs={1} />
        <Grid item xs={10} container direction='row'>
          <Grid item xs={7}>
            <Grid item xs={12}>
              <OrderForm onSubmit={handleSubmit} />
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={4}>
            {loading ? <LoadingIndicator order /> : <FullTable short documents={cartDocuments} />}
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default Component
