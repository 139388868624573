import React, { useState, useEffect } from 'react'
import { useApp } from '../useApp'
import TextField from '@mui/material/TextField'

import Button from '../common/Button'

import styled from 'styled-components'

import fetchAsync from '../backend/fetchAsync'

import LoadIndicator from '../common/LoadingIndicator'
import Toolbar from './Toolbar'
import PopUp from './PopUp'
import i18n from '../../i18n'
import { Grid } from '@material-ui/core'

import MenuItem from '@material-ui/core/MenuItem'
import DeleteIcon from '@mui/icons-material/Delete'

const BACKEND_URL = process.env.REACT_APP_BACKEND || 'https://qr-backend-preview.asab.hpm.agency'

const InputRow = styled(Grid)`
  margin-bottom:20px !important;
`

const Spacer = styled.div`
  height: 30px;
`

const DeleteText = styled.p`
  margin: 0;
  margin-top: 6px;
  padding-left: 25px;
`

const StyledDelete = styled.div`
  float: right;
  margin-top: -3px;
  padding-right: 5px;
`

const Component = () => {
  const [status, setStatus] = useState()
  const { appUser, loading } = useApp()
  const [user, setUser] = useState()
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const languages = [
    'de',
    'en',
    'fr',
    'it'
  ]
  const backendURL = 'https://qr-backend.asab.hpm.agency/user_metadata'
  useEffect(() => {
    if (!loading && appUser && appUser.email) {
      const completeUser = appUser[backendURL]
      setUser(completeUser)
    }
  }, [loading, appUser])

  useEffect(() => {
    if (!loading && !appUser) {
      window.location.assign(window.location.origin)
    }
  }, [loading, appUser])

  useEffect(() => {
    if (!loading && user) {
      setButtonDisabled(JSON.stringify(user) === JSON.stringify(appUser[backendURL]))
    }
  }, [loading, appUser, user])

  const handleDataChange = (field) => (event) => {
    setUser({ ...user, [field]: event.target.value })
  }

  const handleDelete = async () => {
    try {
      // eslint-disable-next-line
      const data = await fetchAsync(`${BACKEND_URL}/users/me`, {
        method: 'DELETE',
        headers: {
          authorization: `Token ${appUser.token}`
        }
      }).then(function (data) {
        if (data.error) {
          return setStatus('errorDelete')
        } else {
          window.location.assign(window.location.origin)
        }
      })
    } catch (err) {
      console.error(err.message)
      setStatus('errorDelete')
    }
  }

  const handleSubmit = async () => {
    try {
      // eslint-disable-next-line
      const data = await fetch(`${BACKEND_URL}/users/me`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Token ${appUser.token}`
        },
        body: JSON.stringify({
          userMetadata: {
            ...user
          }
        })
      }).then(function (data) {
        if (data.status === 200) {
          setStatus('success')
        } else {
          return setStatus('errorSave')
        }
      })
    } catch (err) {
      console.error(err.message)
      setStatus('errorSave')
    }
  }

  return (
    <>
      <Toolbar />
      <Spacer />
      {!!status &&
        <PopUp status={status} setStatus={setStatus} handleDelete={handleDelete} />}
      {loading &&
        <LoadIndicator order />}
      {!loading && user && appUser.email &&
        <Grid container>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <InputRow container direction='row' spacing={1}>
              <Grid item xs={12}><TextField disabled fullWidth label={i18n.t('profile.email')} variant='outlined' value={appUser.email} /></Grid>
            </InputRow>
            <InputRow container direction='row' spacing={1}>
              <Grid item xs={6}><TextField onChange={handleDataChange('customerId')} fullWidth label={i18n.t('profile.customerId')} variant='outlined' value={user.customerId} /></Grid>
              <Grid item xs={6}><TextField onChange={handleDataChange('company')} fullWidth label={i18n.t('profile.company')} variant='outlined' value={user.company} /></Grid>
            </InputRow>
            <InputRow container direction='row' spacing={1}>
              <Grid item xs={6}><TextField onChange={handleDataChange('firstname')} fullWidth label={i18n.t('profile.firstname')} variant='outlined' value={user.firstname} /></Grid>
              <Grid item xs={6}><TextField onChange={handleDataChange('lastname')} fullWidth label={i18n.t('profile.lastname')} variant='outlined' value={user.lastname} /></Grid>
            </InputRow>
            <InputRow container direction='row' spacing={1}>
              <Grid item xs={12}>
                <TextField select fullWidth label={i18n.t('profile.language')} variant='outlined' onChange={handleDataChange('language')} value={user.language}>
                  {languages.map((option) => (
                    <MenuItem key={option} value={option}>
                      {i18n.t(`profile.${option}`)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </InputRow>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button variant='large-inverted' onClick={() => setStatus('delete')}>
                  <DeleteText>{i18n.t('profile.delete')}
                    <StyledDelete>
                      <DeleteIcon />
                    </StyledDelete>
                  </DeleteText>

                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant='large-shadow' disabled={buttonDisabled} onClick={handleSubmit}>{i18n.t('profile.save')}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
    </>
  )
}

export default Component
