import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Button from './Button'

const Container = styled.div`
  min-height: 240px;
  min-width: 460px;
  border-radius: 2px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.5);
  padding: 16px;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 42px;
  color: #0D8CB2;
  border-radius: 42px;
  padding: 16px;
  border: 3px solid rgba(0,160,208,0.2);
  margin: auto;
  display: block;
`
const Title = styled.div`
  margin-top: 16px;
  margin-bottom: 6px;
  color: #4E4E4E;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
`

const Text = styled.div`
  color: #4E4E4E;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  margin-bottom: 32px;
`

const OkButton = styled.div`
  margin: auto;
  display: block;
  width: 100px;
`

export default function Notification ({ title, text, onClick, ...props }) {
  return (
    <Container {...props}>
      <Icon icon={faCheck} />
      <Title>{title}</Title>
      <Text>{text}</Text>
      {!!onClick && <OkButton><Button variant='large-shadow' onClick={onClick}>OK</Button></OkButton>}
    </Container>
  )
}

Notification.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string
}
