import React from 'react'
import { Route } from 'react-router-dom'
import List from './List'
import Viewer from './Viewer'

const Component = () => {
  return (
    <>
      <Route exact path='/:language/:id'>
        <Viewer />
      </Route>
      <Route exact path='/favorites'>
        <List favorites />
      </Route>
      <Route exact path='/:language'>
        <List />
      </Route>
    </>
  )
}

export default Component
