import React from 'react'
import Logo from '../Logo'
import Link from './link'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { Link as RouterLink } from 'react-router-dom'
import i18n, { getLanguage } from '../../i18n'

const Container = styled(Grid)`
  height: 64px;
  border-bottom: 1px solid #C6C1B7;
`

const LogoContainer = styled.div`
  width: 200px;
  padding: 16px;
`

const StyledLogo = styled(Logo)`
  max-height: 48px;
`

const LinkContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
`

const StyledA = styled.a`
  text-decoration: none;
  color: #9D9D9D;
  font-size: 14px;
  line-height: 20px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
`

export { Link }
const Component = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  const handleLogin = async (event) => {
    event.preventDefault()
    await loginWithRedirect({ language: getLanguage() })
  }

  return (
    <Container container space={0} alignItems='center' justifyContent='space-between'>
      <Grid item xs={12} sm={4} md={6}>
        <RouterLink to='/'>
          <LogoContainer><StyledLogo /></LogoContainer>
        </RouterLink>
      </Grid>
      <LinkContainer item xs={12} sm={6} md={5} lg={4}>
        <StyledA href={i18n.t('links.help')} target='_blank'>{i18n.t('navigation.help')}</StyledA>
        <StyledA href={i18n.t('links.legalNotice')} target='_blank'>{i18n.t('navigation.legalNotice')}</StyledA>
        <StyledA href={i18n.t('links.contact')} target='_blank'>{i18n.t('navigation.contact')}</StyledA>
        {!isAuthenticated && <StyledA onClick={handleLogin}>{i18n.t('navigation.login')}</StyledA>}
        {isAuthenticated && <Link to='/profile'>{i18n.t('navigation.profile')}</Link>}
      </LinkContainer>
    </Container>
  )
}

export default Component
