import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #9D9D9D;
  font-size: 14px;
  line-height: 20px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: bold;
`

export default function HeaderLink ({ children, to, ...props }) {
  return <StyledLink to={to} {...props}>{children}</StyledLink>
}
HeaderLink.propTypes = {
  to: PropTypes.string
}
