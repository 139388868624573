import React from 'react'
import Notification from '../common/Notification'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import i18n from '../../i18n'

const OrderedContainer = styled(Grid)`
  margin-top:40px !important;
`

const Component = () => {
  return (
    <>
      <OrderedContainer container direction='row' spacing={0}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Notification title={i18n.t('orderSuccess.successHeadline')} text={i18n.t('orderSuccess.successUnderline')} />
        </Grid>
      </OrderedContainer>

    </>
  )
}

export default Component
