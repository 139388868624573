import React, { useState, useEffect } from 'react'
import { useApp } from '../useApp'
import FullTable from './FullTable'
import { Grid } from '@material-ui/core'
import Button from '../common/Button'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Toolbar from './Toolbar'
import LoadingIndicator from '../common/LoadingIndicator'
import i18n from '../../i18n'

const EmptyContainer = styled.div`
  margin-top: 20%;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Title = styled.div`
  color: #4E4E4E;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`

const Component = () => {
  const { language } = useParams()
  const { loading, documents, documentsAll, toggleCart, setCartAmount, cartLanguage, cartAmount } = useApp()
  const [cartDocuments, setCartDocuments] = useState([])
  const [isOrderable, setIsOrderable] = useState(false)

  useEffect(() => {
    setCartDocuments(documentsAll
      .filter((doc) => {
        return Object.keys(cartLanguage).includes(doc.id)
      })
      .map(document => {
        return {
          ...document,
          title: document.title[cartLanguage[document.id]],
          cartAmount: cartAmount[document.id],
          onCartToggle: toggleCart,
          onChangeAmount: setCartAmount
        }
      }))
  }, [documents, documentsAll, toggleCart, setCartAmount, cartLanguage, cartAmount])

  useEffect(() => {
    const documentWithoutAmount = cartDocuments.find(({ id }) => !cartAmount[id])
    setIsOrderable(!documentWithoutAmount)
  }, [cartDocuments, cartAmount])

  return (
    <>
      <Toolbar to={`/${language}`} title={i18n.t('cart.headline')} />
      {!!cartDocuments.length &&
        <>
          <Grid container direction='row' spacing={0}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              {loading ? <LoadingIndicator order /> : <FullTable documents={cartDocuments} />}
            </Grid>
          </Grid>
          <Grid container direction='row' spacing={0}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              {!!isOrderable && <StyledLink to='/cart/order/'><Button variant='large-shadow'>{i18n.t('cart.order')}</Button></StyledLink>}
              {!isOrderable && <Button disabled variant='large-shadow'>{i18n.t('cart.order')}</Button>}
            </Grid>
          </Grid>
        </>}
      {!cartDocuments.length &&
        <EmptyContainer>
          <Title>{i18n.t('cart.empty')}</Title>
        </EmptyContainer>}

    </>
  )
}

export default Component
