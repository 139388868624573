import React from 'react'
import styled from 'styled-components'
import EmptyIcon from './Empty.svg'
import i18n from '../../i18n'

const Container = styled.div`
  margin-top: 10%;
`

const Icon = styled.img`
  width: 140px;
  color: #fff;
  margin: auto;
  display: block;
  margin-bottom: 50px;
`
const Title = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  color: #4E4E4E;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`

const Text = styled.div`
  color: #4E4E4E;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-bottom: 32px;
`

const Component = () => {
  return (
    <Container>
      <Icon src={EmptyIcon} />
      <Title>{i18n.t('search.noResultTitle')}</Title>
      <Text>{i18n.t('search.noResultText')}</Text>
    </Container>
  )
}

export default Component
