import React from 'react'
import './index.css'
import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css'
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'
import ReactGA from 'react-ga4'

import Documents from './components/Documents'
import Profile from './components/Profile'
import Cart from './components/Cart'
import { Grid } from '@material-ui/core'
import Header from './components/Header'
import Menu from './components/Menu'
import { AppProvider } from './components/useApp'
import './App.css'
import { id, config } from './ganalyticsConfig'

ReactGA.initialize(id, {
  ...config,
  nonce: window.NONCE_ID
})

const GridContainer = styled(Grid)`
  height: calc(100vh - 64px);
`

function usePageViews () {
  const { pathname } = useLocation()
  React.useEffect(() => {
    try {
      ReactGA.send({ hitType: 'pageview', page: pathname })
    } catch (error) {
      console.error(`Could not send analytics pageview ${error.message}`)
    }
  }, [pathname])
}

function App ({ isLoading }) {
  usePageViews()
  return (
    <Switch>
      <Route path='/cart'>
        <Cart />
      </Route>
      <Route path='/profile'>
        <Profile />
      </Route>
      <Route path='/favorites'>
        <Documents favorites />
      </Route>
      <Route path='/:language'>
        <Documents />
      </Route>
      {!isLoading &&
        <Route path='/'>
          <Redirect to='/de' />
        </Route>}
    </Switch>
  )
}

const AppContainer = () => {
  const { isLoading } = useAuth0()
  return (
    <AppProvider>
      <Router basename={`${window.location.pathname}#`}>
        <Header />
        <GridContainer container spacing={0}>
          <Grid item xs={3} md={2}>
            <Menu />
          </Grid>
          <Grid item xs={9} md={10}>
            <App isLoading={isLoading} />
          </Grid>
        </GridContainer>
      </Router>
    </AppProvider>

  )
}

export default AppContainer
