import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'

const Container = styled(Box)`
  background-color: #00A0D0;
  height: 100%;
`

const Item = styled.div`
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  padding: 8px 0 8px 8px;

  &:hover {
    background-color: #0D8CB2;
  }

  &.active {
    background-color: #0D8CB2;
    box-shadow: inset -10px 0 0 0 rgba(0,0,0,0.5);
  }
`

const MenuLink = styled(Link)`
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  display: block;
  line-height: 32px;
`

export default function MainNavigation ({ variant, entries, ...props }) {
  return (
    <Container width='100%' height='100%' {...props}>
      {entries.map(({ id, to, title, active }) => (
        <Item className={active ? 'active' : ''} key={id}><MenuLink to={to}>{title}</MenuLink></Item>
      ))}
    </Container>
  )
}

MainNavigation.propTypes = {
  entries: PropTypes.array
}
