export const config = {
  debug: process.env.REACT_APP_GA_DEBUG || false,
  titleCase: false,
  gaOptions: {
    anonymizeIp: true,
    siteSpeedSampleRate: 100
  }
}

export const id = process.env.REACT_APP_GA_TRACKINGID

const gaAnalyticsConfig = {
  id,
  config
}

export default gaAnalyticsConfig
