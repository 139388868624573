import React from 'react'

const SvgFoto = props => (
  <svg viewBox='0 0 18 18' fill='#939393' {...props}>
    <defs>
      <path
        d='M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z'
        id='foto_svg__a'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#foto_svg__a'
      transform='translate(-3 -3)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgFoto
