import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #00A0D0;
  background-color: #fff;
  border: 1px solid #00A0D0;
  border-radius: 15px;
  cursor: pointer;
  padding: 0 12px 0 12px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

  &:hover {
    background-color: #eee;
  }

  &:disabled {
    border-color: #D9D5CF;
    color: #D9D5CF;
  }

  &.primary {
    background-color: #00A0D0;
    color: #fff;

    &:hover {
      background-color: #33D3F3;
    }
  }


  &.icon {
    color: #000;
    border-color: #D9D5CF;

    &:hover {
      color:#fff;
      background-color: #00A0D0;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &.icon-logout {
    color: #000;
    border-color: #D9D5CF;

    &:hover {
      color: #00A0D0;
      border-color: #00A0D0;
      background-color: #FFF;
    }

    &:active {
      color: #FFF;
      background-color: #00A0D0;
    }
  }

  &.large {
    font-size: 14px;
    line-height: 17px;
    height: 40px;
    width: 100%;
    background-color: #00A0D0;
    border-radius: 2px;
    color: #fff;
    font-weight: 500;

    &:hover {
      background-color: #A2A2A2;
    }

    &:disabled {
      background-color: #A2A2A2;
      cursor: not-allowed;
    }
  }

  &.large-shadow {
    font-size: 14px;
    line-height: 17px;
    height: 40px;
    width: 100%;
    background-color: #00A0D0;
    border-radius: 2px;
    color: #fff;
    font-weight: 500;

    &:disabled {
      background-color: #A2A2A2;
      cursor: not-allowed;
    }

    &:hover:enabled {
      box-shadow: 0px 3px 8px 1px #A2A2A2;
    }
  }

  &.large-inverted {
    font-size: 14px;
    line-height: 17px;
    height: 40px;
    width: 100%;
    color: #00A0D0;
    border-radius: 2px;
    background-color: #fff;
    font-weight: 500;
    position: relative;
    padding: 0;

    &:hover {
      box-shadow: 0px 3px 8px 1px #A2A2A2;
    }

    &:disabled {
      background-color: #A2A2A2;
      cursor: not-allowed;
    }
  }


  &.filled {
    font-size: 14px;
    line-height: 17px;
    height: 40px;
    background-color: #00A0D0;
    color: #fff;
    font-weight: 500;

    &:hover {
      background-color: #A2A2A2;
    }

    &:disabled {
      background-color: #A2A2A2;
    }
  }

  &.icon-filled {
    color: #000;
    border-color: #D9D5CF;
    position: relative;
    &:hover {
      background-color: #00A0D0;
    }
  }

  &.icon-filled:before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background: #00A0D0;
    right: 5px;
    top: 7px;
    border-radius: 100%;
    z-index: 1;
  }

  &.icon-filled:hover:before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background: #FFFFFF;
    right: 5px;
    top: 7px;
    border-radius: 100%;
    z-index: 1;
  }

   &.icon-active {
    background-color: #00A0D0;
    color: #fff;

    &:hover {
      background-color: #A2A2A2;
    }

    &:disabled {
      background-color: #A2A2A2;
    }
  }

  :focus {
    outline: none;
    box-shadow: none;
  }
`

export default function CustomButton ({ variant, children, ...props }) {
  return (
    <Button className={[variant]} {...props}>{children}</Button>
  )
}

CustomButton.propTypes = {
  variant: PropTypes.string
}
