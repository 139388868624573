import React, { useState, useEffect } from 'react'

const Component = ({ documents }) => {
  const [totalCartAmount, setTotalCartAmount] = useState(0)

  useEffect(() => {
    const totalAmount = documents.reduce((totalAmount, document) => {
      return totalAmount + document.cartAmount || 0
    }, 0)
    setTotalCartAmount(totalAmount)
  }, [documents])

  return <>{totalCartAmount}</>
}

export default Component
