import React, { useState, useEffect } from 'react'
import SearchBar from './SearchBar'
import styled from 'styled-components'
import { useApp } from '../useApp'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faStar } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Button from '../common/Button'
import useUser from '../backend/useUser'
import i18n from '../../i18n'

const Container = styled(Grid)`
  height: 64px;
  border-bottom: 1px solid #C6C1B7;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0 20px 0 20px;
`

const ButtonText = styled.div`
  padding-left: 10px;
  @media (max-width: 900px) {
    display: none;
  }
`

const ToolbarLink = styled(Link)`
  text-decoration: none !important;
`

const Component = ({ favorites }) => {
  const { documentsAll, cartLanguage, setFilterText } = useApp()
  const [hasCartDocuments, setHasCartDocuments] = useState(false)
  const user = useUser()

  useEffect(() => {
    setHasCartDocuments(false)
    if (!documentsAll) {
      return
    }
    const tempDocuments = documentsAll.filter((doc) => {
      return Object.keys(cartLanguage).includes(doc.id)
    })

    if (tempDocuments.length) {
      setHasCartDocuments(true)
    }
  }, [documentsAll, cartLanguage])

  const search = (text) => {
    setFilterText(text)
  }

  const showFavorites = user && user.token
  return (
    <Container container spacing={0} alignItems='center' justifyContent='space-between'>
      <Grid item xs={3} sm={5}><SearchBar onSubmit={search} /></Grid>
      <Grid item container xs={4} spacing={1} justifyContent='flex-end' wrap='nowrap'>
        {showFavorites && (
          <Grid item>
            <ToolbarLink to='/favorites'>
              <Button variant={favorites ? 'icon-active' : 'icon'}>
                <FontAwesomeIcon icon={faStar} />
                <ButtonText>{i18n.t('documentFilter.favorites')}</ButtonText>
              </Button>
            </ToolbarLink>
          </Grid>
        )}
        <Grid item>
          <ToolbarLink to='cart'>
            <Button variant={hasCartDocuments ? 'icon-filled' : 'icon'}>
              <FontAwesomeIcon icon={faShoppingCart} />
              <ButtonText>{i18n.t('documentFilter.cart')}</ButtonText>
            </Button>
          </ToolbarLink>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Component
