const extractTitles = obj => {
  const result = {}
  const keys = Object.keys(obj)
  const prefix = 'title_'
  keys.forEach(key => {
    if (key.substr(0, prefix.length) !== prefix) {
      return
    }

    result[key.substr(prefix.length)] = obj[key]
  })

  return result
}

const extractTitlesInList = list => {
  return list.map(({ id, ...entry }) => {
    return {
      id,
      title: extractTitles(entry)
    }
  })
}

const isLessThan4WeeksOld = (newSince) => {
  const fourWeeksInSeconds = 60 * 60 * 24 * 7 * 4
  const documentNewSinceSeconds = new Date(newSince).getTime() / 1000
  const documentOldSeconds = documentNewSinceSeconds + fourWeeksInSeconds
  const currentTime = new Date().getTime() / 1000

  if (currentTime < documentOldSeconds) {
    return true
  }
  return false
}

const mapDocument = (document) => {
  const downloadUrl = document.ref ? document.ref + (document.ref.includes('?') ? '&' : '?') + 'display=true' : undefined
  const isNew = isLessThan4WeeksOld(document.newSince)
  return {
    // _raw: document,
    id: document.id,
    title: extractTitles(document),
    youtubeId: document.youtube,
    downloadUrl,
    thumbnail: document.refThumbnail,
    mimetype: document.mimetype,
    brands: extractTitlesInList(document.brands),
    topics: extractTitlesInList(document.topics),
    types: extractTitlesInList(document.docarts),
    productGroups: extractTitlesInList(document.productgroup),
    productSubGroups: extractTitlesInList(document.productsubgroup),
    createdAt: new Date(document.createdAt),
    publishedAt: new Date(document.publishDate),
    newSince: document.newSince,
    isNew,
    orderable: !!document.availableForOrder,
    favorite: !!document.favorite,
    filesizeBytes: document.filesizeBytes
  }
}

export { mapDocument }
