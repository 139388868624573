import React from 'react'
import { Route } from 'react-router-dom'
import List from './List'
import Order from './Order'
import Ordered from './Ordered'

const Component = () => {
  return (
    <>
      <Route strict exact path='/cart/:language?'>
        <List />
      </Route>
      <Route exact path='/cart/order/:language?'>
        <Order />
      </Route>
      <Route exact path='/cart/ordered/:language?'>
        <Ordered />
      </Route>
    </>
  )
}

export default Component
