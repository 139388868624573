import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DocumentViewer from '../Viewer'
import { useApp } from '../useApp'

const Component = () => {
  const { language, id } = useParams()
  const { documents } = useApp()
  const [document, setDocument] = useState()
  useEffect(() => {
    setDocument(documents.find(document => document.id === id))
  }, [language, id, documents])

  return (
    <DocumentViewer language={language} document={document} />
  )
}

export default Component
