import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Toolbar from './Toolbar'
import { Grid } from '@material-ui/core'

const YoutubeContainer = styled(Grid)`
  position: relative;
  height: 90vh;

  max-width: 1024px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
`

const Youtube = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Error = styled.div`
  color: #933;
  text-align: center;
  margin-top: 16px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
`

export default function VideoViewer ({ language, document }) {
  const { youtubeId } = document

  if (!youtubeId) {
    return (
      <>
        <Toolbar language={language} document={document} />
        <Error>YouTube Video not correctly configured</Error>
      </>
    )
  }

  return (
    <>
      <Toolbar language={language} document={document} />
      <YoutubeContainer>
        <Youtube src={`https://www.youtube-nocookie.com/embed/${youtubeId}`} frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen />
      </YoutubeContainer>
    </>
  )
}

VideoViewer.propTypes = {
  document: PropTypes.object,
  language: PropTypes.string
}
