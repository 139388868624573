import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import Success from './Success.png'
import Error from './Error.png'

import i18n from '../../i18n'
import Button from '../common/Button'

import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 30px;
  text-align: center;
`

const StyledImage = styled.img`
  width: 80px;
  align-self: center;
`

const StyledActions = styled(DialogActions)`
  justify-content: center !important;
  max-width: 70%;
  margin-left: 13.5%;
`

export default function AlertDialog ({ status, setStatus, handleDelete }) {
  const handleClose = () => {
    if (status.includes('error') || status === 'delete') {
      setStatus(false)
    } else {
      window.location.reload()
    }
  }

  return (
    <Dialog
      open={!!status}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Wrapper>
        {(status === 'errorSave' || status === 'errorDelete') &&
          <>
            <StyledImage src={Error} />
            <DialogContent>
              {status === 'errorSave' &&
                <DialogContentText id='alert-dialog-description'>
                  {i18n.t('profile.errorSave')}
                </DialogContentText>}
              {status === 'errorDelete' &&
                <DialogContentText id='alert-dialog-description'>
                  {i18n.t('profile.errorDelete')}
                </DialogContentText>}
            </DialogContent>
          </>}
        {status === 'success' &&
          <>
            <StyledImage src={Success} />
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {i18n.t('profile.successSave')}
              </DialogContentText>
            </DialogContent>
          </>}
        {status === 'delete' &&
          <>
            <DialogTitle id='alert-dialog-title'>
              {i18n.t('profile.delete')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {i18n.t('profile.deleteDescription')}
              </DialogContentText>
            </DialogContent>
          </>}
        {status === 'delete' &&
          <StyledActions>
            <Button variant='large-shadow' onClick={handleDelete}>{i18n.t('profile.yes')}</Button>
            <Button variant='large-shadow' onClick={handleClose}>{i18n.t('profile.cancel')}</Button>
          </StyledActions>}
        {status !== 'delete' &&
          <StyledActions>
            <Button variant='large-shadow' onClick={handleClose}>{i18n.t('profile.ok')}</Button>
          </StyledActions>}
      </Wrapper>
    </Dialog>

  )
}
