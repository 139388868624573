import React, { useState, useEffect } from 'react'
import MainNavigation from './MainNavigation'
import { useApp } from './useApp'
import i18n from '../i18n'

const LANGUAGE_NAMES = {
  de: i18n.t('sideBar.german'),
  en: i18n.t('sideBar.english'),
  it: i18n.t('sideBar.italian'),
  fr: i18n.t('sideBar.french')
}

const Component = () => {
  const { availableLanguages, filterLanguages } = useApp()
  const [mainNavigationItems, setMainNavigationItems] = useState([])

  useEffect(() => {
    if (!availableLanguages) {
      return
    }
    setMainNavigationItems(availableLanguages.map(language => {
      return {
        id: language,
        title: LANGUAGE_NAMES[language],
        to: `/${language}`,
        active: filterLanguages && filterLanguages.includes(language)
      }
    }))
  }, [availableLanguages, filterLanguages])

  return <MainNavigation entries={mainNavigationItems} />
}

export default Component
