import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

const Wrapper = styled(Grid)`
    text-align: center;
    line-height: ;
    line-height: ${props => props.order ? 'calc(100vh - 128px)' : 'calc(100vh - 64px)'}
`

const LoadIndicator = ({ order = false }) => {
  return (
    <Wrapper item xs={12} order={order || undefined}>
      <CircularProgress style={{ color: '#00528C' }} />
    </Wrapper>
  )
}

export default LoadIndicator
