import React, { useState } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import cartAdd from './cartAdd.svg'
import cartRemove from './cartRemove.svg'
import copyIcon from './copy.svg'
import copiedIcon from './copied.svg'
import starRegular from './star-regular.svg'
import starSolid from './star-solid.svg'

const BACKEND_URL = process.env.REACT_APP_BACKEND || 'https://qr-backend-preview.asab.hpm.agency'

const Container = styled.div`
  background-size: 100%;
  position: absolute;
  top: 8px;
  ${props => props.right ? 'right: 8px;' : 'left: 8px;'}
  width: 20px;

  & > div {
    margin-bottom: 8px;
  }
`

const Icon = styled.img`
  width: 20px;
`

export default function IconBar ({ id, orderable, inCart, onCartToggle, isLoggedIn, copy, favorite, onFavoriteToggle, fallbackCartLanguage, ...props }) {
  const cartIcon = inCart ? cartRemove : cartAdd
  const favoriteIcon = favorite ? starSolid : starRegular
  const [downloadUrl] = useState(`${BACKEND_URL}/d/${id}`)
  const [copied, setCopied] = useState(false)
  const toggleCart = () => {
    if (onCartToggle) {
      onCartToggle(id, fallbackCartLanguage)
    }
  }

  const toggleFavorite = () => {
    if (onFavoriteToggle) {
      onFavoriteToggle(id)
    }
  }

  const copiedUrl = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  return (
    <Container {...props}>
      {copy && (
        <CopyToClipboard text={downloadUrl} onCopy={copiedUrl}>
          <div><Icon src={!copied ? copyIcon : copiedIcon} /></div>
        </CopyToClipboard>
      )}
      {!!isLoggedIn &&
        <div onClick={toggleFavorite} className={favorite ? 'active' : ''}><Icon src={favoriteIcon} /></div>}
      {!!orderable &&
        <div onClick={toggleCart} className={inCart ? 'active' : ''}><Icon src={cartIcon} /></div>}
      {}
    </Container>
  )
}
