import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import i18n from '../../i18n'

const Container = styled.div`
  display: flex
`

const Input = styled.input`
  height: 32px;
  width: 100%;
  padding: 0 6px 0 12px;
  font-size: 12px;
  line-height: 1.42rem;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-right: none;
  display: inline-block;
  min-width: 150px;

  :focus {
    outline: none;
    box-shadow: none;
  }
`

const Submit = styled.button`
  height: 34px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42rem;
  color: #fff;
  background-color: #00A0D0;
  border: 1px solid #ccc;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: inline-block;
  cursor: pointer;

  :focus {
    outline: none;
    box-shadow: none;
  }
`

export default function SearchBar ({ onSubmit, ...props }) {
  const [searchString, setSearchString] = useState('')

  const updateSearchString = (event) => {
    setSearchString(event.target.value)
  }

  const search = () => {
    if (onSubmit) {
      onSubmit(searchString)
    }
  }

  const searchOnReturn = ({ key }) => {
    if (key === 'Enter') {
      search()
    }
  }

  return (
    <Container {...props}>
      <Input placeholder={i18n.t('documentFilter.search')} value={searchString} onChange={updateSearchString} onKeyDown={searchOnReturn} /><Submit onClick={search}><FontAwesomeIcon icon={faSearch} /></Submit>
    </Container>
  )
}

SearchBar.propTypes = {
  onSubmit: PropTypes.func
}
