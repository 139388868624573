import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import Button from '../common/Button'
import i18n from '../../i18n'
import LogoutIcon from '@mui/icons-material/ExitToApp'
import { useAuth0 } from '@auth0/auth0-react'

const Container = styled(Grid)`
  height: 64px;
  border-bottom: 1px solid #C6C1B7;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  text-align: center;
  width: 100%;
  padding: 0 20px 0 20px;
`

const StyledLogout = styled(LogoutIcon)`
  float: left;
  display: block;
  height: 21.5px !important;
  width: 21.5px !important;
  margin-top: 1px;
  margin-right: 5px;
`

const LogoutText = styled.p`
  margin: 0;
  margin-top: 4px;
  display: block;
  float: left;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-weight: 400;
`

const Spacer = styled.div`
  width: 100px;
`

const Component = () => {
  const { logout } = useAuth0()
  return (
    <>
      <Container container spacing={0} alignItems='center' justifyContent='space-between'>
        <Grid item><Spacer /></Grid>
        <Grid item>
          {i18n.t('profile.myProfile')}
        </Grid>
        <Grid item>
          <Button variant='icon-logout' onClick={() => logout({ returnTo: window.location.origin })}>
            <StyledLogout />
            <LogoutText>{i18n.t('profile.logout')}</LogoutText>
          </Button>
        </Grid>
      </Container>
    </>
  )
}

export default Component
