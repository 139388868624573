import { useState, useEffect } from 'react'
import useDocuments from './useDocuments'

const useFilteredDocuments = (user) => {
  const { documents, documentsFavorite, loading, handleFavorite } = useDocuments(user)
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const [filteredFavoriteDocuments, setFilteredFavoriteDocuments] = useState([])
  const [availableLanguages, setAvailableLanguages] = useState([])
  const [availableProductGroups, setAvailableProductGroups] = useState([])
  const [availableTopics, setAvailableTopics] = useState([])
  const [availableBrands, setAvailableBrands] = useState([])
  const [availableTypes, setAvailableTypes] = useState([])

  const [filterLanguages, setFilterLanguages] = useState([])
  const [filterProductGroups, setFilterProductGroups] = useState([])
  const [filterTopics, setFilterTopics] = useState([])
  const [filterBrands, setFilterBrands] = useState([])
  const [filterTypes, setFilterTypes] = useState([])
  const [filterText, setFilterText] = useState('')

  useEffect(() => {
    const brandById = {}
    const typeById = {}
    const topicById = {}
    const productGroupById = {}
    const languages = {}
    documents.forEach(({ brands, productGroups, topics, types, title }) => {
      topics.forEach(topic => { topicById[topic.id] = topic })
      brands.forEach(brand => { brandById[brand.id] = brand })
      types.forEach(type => { typeById[type.id] = type })
      productGroups.forEach(productGroup => { productGroupById[productGroup.id] = productGroup })

      for (const language in title) {
        if (title[language]) {
          languages[language] = true
        }
      }
    })

    setAvailableLanguages(Object.keys(languages))
    setAvailableBrands(Object.values(brandById))
    setAvailableTypes(Object.values(typeById))
    setAvailableTopics(Object.values(topicById))
    setAvailableProductGroups(Object.values(productGroupById))
  }, [documents])

  useEffect(() => {
    setFilteredDocuments(
      documents
        .filter(({ title }) => {
          const matchingTitles = filterLanguages.filter(language => !!title[language])
          return matchingTitles.length > 0
        })
        .filter(({ productGroups }) => {
          return !!(!filterProductGroups.length || productGroups.find(({ id }) => filterProductGroups.includes(id)))
        })
        .filter(({ topics }) => {
          return !!(!filterTopics.length || topics.find(({ id }) => filterTopics.includes(id)))
        })
        .filter(({ brands }) => {
          return !!(!filterBrands.length || brands.find(({ id }) => filterBrands.includes(id)))
        })
        .filter(({ types }) => {
          return !!(!filterTypes.length || types.find(({ id }) => filterTypes.includes(id)))
        })
        .filter(({ title }) => {
          return Object.values(title).join(' ').toLowerCase().includes(String(filterText).toLowerCase())
        })
    )

    setFilteredFavoriteDocuments(
      documentsFavorite
        .filter(({ productGroups }) => {
          return !!(!filterProductGroups.length || productGroups.find(({ id }) => filterProductGroups.includes(id)))
        })
        .filter(({ topics }) => {
          return !!(!filterTopics.length || topics.find(({ id }) => filterTopics.includes(id)))
        })
        .filter(({ brands }) => {
          return !!(!filterBrands.length || brands.find(({ id }) => filterBrands.includes(id)))
        })
        .filter(({ types }) => {
          return !!(!filterTypes.length || types.find(({ id }) => filterTypes.includes(id)))
        })
        .filter(({ title }) => {
          return Object.values(title).join(' ').toLowerCase().includes(String(filterText).toLowerCase())
        })
    )
  }, [documents, filterLanguages, filterProductGroups, filterTopics, filterBrands, filterTypes, filterText, documentsFavorite])

  return {
    documents: filteredDocuments,
    loading,
    availableProductGroups,
    availableBrands,
    availableLanguages,
    availableTopics,
    availableTypes,

    filterLanguages,
    setFilterLanguages,
    filterProductGroups,
    setFilterProductGroups,
    filterTopics,
    setFilterTopics,
    filterBrands,
    setFilterBrands,
    filterTypes,
    setFilterTypes,
    filterText,
    setFilterText,

    documentsAll: documents,
    documentsFavorite: filteredFavoriteDocuments,
    handleFavorite

  }
}

export default useFilteredDocuments
