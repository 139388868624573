import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useApp } from '../useApp'
import SearchResult from '../SearchResult'
import LoadingIndicator from '../common/LoadingIndicator'
import i18n, { getLanguage } from '../../i18n'

const SUPPORTED_MIMETYPES = [
  'image/jpg',
  'image/png',
  'image/jpg'
]

const SUPPORTED_LINK_MIMETYPES = [
  'application/pdf'
]

const Component = ({ favorites }) => {
  const { language } = useParams()
  const {
    loading,
    documents,
    setFilterLanguages,
    availableProductGroups, availableBrands, availableLanguages, availableTopics, availableTypes,
    setFilterProductGroups, setFilterTopics, setFilterBrands, setFilterTypes,
    filterProductGroups, filterTopics, filterBrands, filterTypes,
    toggleCart,
    toggleFavorite,
    appUser,
    documentsFavorite
  } = useApp()

  const [searchResult, setSearchResult] = useState([])
  const [filters, setFilters] = useState([])
  const [favoriteCounter, setFavoriteCounter] = useState(0)

  const simpleList = useCallback(({ id, title }) => {
    return {
      id,
      title: title[getLanguage()]
    }
  }, [])

  useEffect(() => {
    setFilters([
      {
        title: i18n.t('documentFilter.productGroup'),
        options: (availableProductGroups || []).map(simpleList),
        onFilter: setFilterProductGroups,
        active: filterProductGroups || []
      },
      {
        title: i18n.t('documentFilter.brand'),
        options: (availableBrands || []).map(simpleList),
        onFilter: setFilterBrands,
        active: filterBrands || []
      },
      {
        title: i18n.t('documentFilter.topics'),
        options: (availableTopics || []).map(simpleList),
        onFilter: setFilterTopics,
        active: filterTopics || []
      },
      {
        title: i18n.t('documentFilter.documentTypes'),
        options: (availableTypes || []).map(simpleList),
        onFilter: setFilterTypes,
        active: filterTypes || []
      }
    ])
  }, [language, simpleList, availableProductGroups, availableBrands, availableLanguages, availableTopics, availableTypes, setFilterProductGroups, setFilterBrands, setFilterTopics, setFilterTypes, filterProductGroups, filterTopics, filterBrands, filterTypes])

  useEffect(() => {
    setFilterLanguages([language])
  }, [language, setFilterLanguages])

  const toggleCartWithLanguage = useCallback((id, fallbackCartLanguage) => {
    toggleCart(id, language || fallbackCartLanguage)
  }, [language, toggleCart])

  const favoriteWithCounter = useCallback((params) => {
    toggleFavorite(params)
    setFavoriteCounter(favoriteCounter + 1)
  }, [toggleFavorite, setFavoriteCounter, favoriteCounter])

  useEffect(() => {
    if (favorites) {
      return
    }
    setSearchResult(documents.map(document => {
      const isSupported = SUPPORTED_MIMETYPES.includes(document.mimetype) || document.youtubeId
      return {
        ...document,
        to: isSupported ? `/${language}/${document.id}` : document.downloadUrl,
        title: document.title[language],
        download: !SUPPORTED_LINK_MIMETYPES.includes(document.mimetype),
        onCartToggle: toggleCartWithLanguage,
        onFavoriteToggle: toggleFavorite
      }
    }))
  }, [toggleCartWithLanguage, language, documents, toggleCart, toggleFavorite, favorites])

  useEffect(() => {
    if (!favorites) {
      return
    }
    if (documentsFavorite) {
      setSearchResult([...documentsFavorite.map(document => {
        let fallbackCartLanguage
        if (document.title[getLanguage()]) {
          fallbackCartLanguage = getLanguage()
        } else if (document.title.de) {
          fallbackCartLanguage = 'de'
        } else if (document.title.en) {
          fallbackCartLanguage = 'en'
        } else if (document.title.it) {
          fallbackCartLanguage = 'it'
        } else if (document.title.fr) {
          fallbackCartLanguage = 'fr'
        }

        const isSupported = SUPPORTED_MIMETYPES.includes(document.mimetype) || document.youtubeId
        return {
          ...document,
          to: isSupported ? `/${availableLanguages.includes(getLanguage()) ? getLanguage() : 'de'}/${document.id}` : document.downloadUrl,
          title: document.title[getLanguage()] || document.title.de || document.title.en || document.title.it || document.title.fr,
          download: !SUPPORTED_LINK_MIMETYPES.includes(document.mimetype),
          onCartToggle: toggleCartWithLanguage,
          onFavoriteToggle: favoriteWithCounter,
          fallbackCartLanguage
        }
      })])
    }
  }, [toggleCartWithLanguage, documentsFavorite, favoriteWithCounter, availableLanguages, favorites])

  return (
    <>
      {loading && <LoadingIndicator />}
      {!loading && <SearchResult filters={filters} documents={searchResult} appUser={appUser} favoriteCounter={favoriteCounter} favorites={favorites} />}
    </>
  )
}

export default Component
