import React from 'react'
import styled from 'styled-components'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

const Thumbnail = styled.div`
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  & img {
    border: 1px solid #dbdbdb;
    max-width: 63px;
    max-height: 51px;
  }
`

const Title = styled.div`
  font-size: 14px;
  margin-left: 20px;
`

const TitleCell = styled.div`
  display: flex;
  align-items: center;
`

const Component = ({ thumbnail, title, cartAmount }) => (
  <TableRow>
    <TableCell>
      <TitleCell>
        <Thumbnail><img alt='' src={thumbnail} /></Thumbnail>
        <Title>{title}</Title>
      </TitleCell>
    </TableCell>
    <TableCell align='right'>{cartAmount}</TableCell>
  </TableRow>
)

export default Component
