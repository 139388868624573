import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Document from './Document'
import Filter from './Filter'
import Toolbar from './Toolbar'
import Button from '../common/Button'
import Empty from './Empty'
import { useApp } from '../useApp'

const Container = styled.div`
  background-color: #e7e7e7;
  min-height: 100%;
`

const ResultContainer = styled.div`
  padding-left: 16px;
`

const AvailableFilters = styled.div`
  padding: 0 16px 16px 8px;
  margin-top: 16px;
`

const ActiveFilters = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`

const StyledDocument = styled(Document)`
  margin: 8px;
`

const RemoveFilterButton = styled.div`
  margin-right: 8px;
  margin-bottom: 16px;
  display: inline-block;
`

const RemoveX = styled.span`
  font-weight: bold;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4px;
`

export default function SearchResult ({ filters, documents, appUser, favoriteCounter, favorites, ...props }) {
  const isLoggedIn = !(appUser && Object.keys(appUser).length === 0 && Object.getPrototypeOf(appUser) === Object.prototype)
  const history = useHistory()
  const [activeFilter, setActiveFilter] = useState([])
  const [language, setLanguage] = useState('')
  const { passwordChangeRequired } = useApp()

  useEffect(() => {
    const activeFilter = []
    filters.forEach(filter => {
      (filter.active || []).forEach(activeId => {
        const singleFilter = {
          ...filter.options.find(({ id }) => id === activeId),
          active: filter.active,
          onFilter: filter.onFilter
        }
        activeFilter.push(singleFilter)
      })
    })
    setActiveFilter(activeFilter)
  }, [filters])

  const removeFilter = (id, onFilter, active) => () => {
    const newActive = active.filter(activeId => activeId !== id)
    onFilter(newActive)
  }

  useEffect(() => {
    if (documents[0]) {
      const tempLanguage = documents[0].cartLanguage
      setLanguage(tempLanguage)
    }
  }, [documents])

  useEffect(() => {
    if (passwordChangeRequired) {
      history.push('/password/reset/')
    }
  }, [history, passwordChangeRequired])

  const sortedDocuments = documents.filter(({ isNew }) => !isNew).sort((a, b) => String(a.title || '').localeCompare(String(b.title || ''), language || 'de', { numeric: true }))
  const sortedNewDocuments = documents.filter(({ isNew }) => isNew).sort((a, b) => new Date(b.newSince) - new Date(a.newSince))
  return (
    <Container>
      <Toolbar language={language} favorites={favorites} />
      <ResultContainer>
        <AvailableFilters>
          {!!filters && filters.map(({ title, options, active, onFilter }) => (
            <Filter key={title} title={title} options={options} active={active} onFilter={onFilter} />
          ))}
        </AvailableFilters>
        {activeFilter.length > 0 &&
          <ActiveFilters>
            {activeFilter.map(({ id, title, active, onFilter }) => (
              <RemoveFilterButton key={id}><Button variant='filled' onClick={removeFilter(id, onFilter, active)}>{title} <RemoveX>✕</RemoveX></Button></RemoveFilterButton>
            ))}
          </ActiveFilters>}
        {sortedNewDocuments.map(document => <StyledDocument key={document.id} {...document} isLoggedIn={isLoggedIn} favoriteCounter={favoriteCounter} />)}
        {sortedDocuments.map(document => <StyledDocument key={document.id} {...document} isLoggedIn={isLoggedIn} favoriteCounter={favoriteCounter} />)}
        {!documents.length && <Empty />}
      </ResultContainer>
    </Container>
  )
}

SearchResult.propTypes = {
  documents: PropTypes.array.isRequired,
  filters: PropTypes.array
}
