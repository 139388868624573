import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import i18n from '../../i18n'
import Button from '../common/Button'
import { Link } from 'react-router-dom'
import useUser from '../backend/useUser'

const Container = styled(Grid)`
  height: 64px;
  border-bottom: 1px solid #C6C1B7;
  background-color: #fff;
  padding: 0 20px 0 20px;
`

const ButtonText = styled.div`
  padding-left: 10px;
  @media (max-width: 900px) {
    display: none;
  }
`

const ToolbarLink = styled(Link)`
  text-decoration: none !important;
`

const Component = ({ title }) => {
  const user = useUser()
  const showFavorites = user && user.token
  return (
    <Container container spacing={0} alignItems='center' justifyContent='space-between'>
      <Grid item xs={4} />
      <Grid item>
        {title}
      </Grid>
      <Grid item container xs={4} spacing={1} justifyContent='flex-end' wrap='nowrap'>
        {showFavorites && (
          <Grid item>
            <ToolbarLink to='/favorites'>
              <Button variant='icon'>
                <FontAwesomeIcon icon={faStar} />
                <ButtonText>{i18n.t('documentFilter.favorites')}</ButtonText>
              </Button>
            </ToolbarLink>
          </Grid>
        )}
        <Grid item>
          <ToolbarLink to='/cart'>
            <Button variant='icon-active'>
              <FontAwesomeIcon icon={faShoppingCart} />
              <ButtonText>{i18n.t('documentFilter.cart')}</ButtonText>
            </Button>
          </ToolbarLink>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Component
