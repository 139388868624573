import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import styled from 'styled-components'
import TotalCartAmount from '../TotalCartAmount'
import Document from './Document'
import i18n from '../../../../i18n'

const TotalSum = styled(TableCell)`
  margin-top:20px;
  margin-bottom:40px;
  font-weight: bold;
  color: #A2A2A2;
  text-align: right;
  border-bottom: 0;
`

const Headline = styled(TableCell)`
  color: #A2A2A2;
  font-size: 12;
`

const Component = ({ documents }) => (
  <TableContainer>
    <Table>

      <TableHead>
        <TableRow>
          <Headline>{i18n.t('cart.brochureOrder')}</Headline>
          <Headline>{i18n.t('cart.amount')}</Headline>
        </TableRow>
      </TableHead>

      <TableBody>
        {documents && documents.map(document => <Document key={document.id} {...document} />)}

        <TableRow>
          <TableCell>{i18n.t('cart.sum')}</TableCell>
          <TotalSum align='right'><b><TotalCartAmount documents={documents} /> {i18n.t('cart.article')}</b></TotalSum>
        </TableRow>
      </TableBody>

    </Table>
  </TableContainer>
)

export default Component
