import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Popover } from '@material-ui/core'
import styled from 'styled-components'
import shadow from './shadow.png'
import IconBar from './IconBar'
import Link from './Link'
import i18n from '../../../i18n'
import Image from '../../../assets/Image'
import CustomButton from '../../common/Button'
import { partial } from 'filesize'

const SUPPORTED_PREVIEW_TYPES = ['image', 'pdf']
const browserSupportsFiletype = mimetype => SUPPORTED_PREVIEW_TYPES.some(filetype => mimetype.includes(filetype))

const formatAsLocalizeHumanReadableFilesize = partial({ locale: i18n.locale })

const Container = styled.div`
  width: 190px;
  height: 220px;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  display: inline-grid;
  position: relative;

  &:hover {
    background-color: #eee;
  }
`

const Thumbnail = styled.div`
  margin: auto;
  margin-top: 10px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 122px;

  & img {
    border: 1px solid #dbdbdb;
    max-width: 120px;
    max-height: 120px;
  }
`

const Shadow = styled.div`
  width: 128px;
  height: 18px;
  text-align: center;
  margin: auto;

  & img {
    width: 128px;
  }
`

const MAX_TITLE_LINES = 3

const Title = styled.div`
  margin: auto;
  width: 130px;
  height: 38px;
  margin-bottom: 8px;
  font-size: 11px;
  color: #424242;
  word-wrap: break-word;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${MAX_TITLE_LINES}; /* number of lines to show */
          line-clamp: ${MAX_TITLE_LINES};
  -webkit-box-orient: vertical;
`

const PopoverTitle = styled.div`
  margin: auto;
  margin-bottom: 16px;
  font-size: 16px;
  color: #424242;
  font-weight: bold;
  word-wrap: break-word;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${MAX_TITLE_LINES}; /* number of lines to show */
          line-clamp: ${MAX_TITLE_LINES};
  -webkit-box-orient: vertical;
`

const IndicatorNew = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  color: #03a0cf;
  display: flex;
  justify-content: center;
  line-height: 8px;
`

const BlueCircle = styled.div`
  border-radius: 4px;
  height: 8px;
  width: 8px;
  background-color: #03a0cf;
  display: inline-block;
  margin-right: 5px;
`

const PlaceholderImage = styled(Image)`
  width: 120px;
`

const ModalContainer = styled.div`
  width: 300px;
  padding: 16px;
`

const DownloadMessage = styled.div`
  margin-bottom: 16px;
  white-space: pre-wrap;
  text-align: center;
  font-size: 12px;
`

const showTitleOnHoverAfterLength = 66

export default function Document ({
  id,
  to,
  title,
  thumbnail,
  orderable,
  inCart,
  onCartToggle,
  download,
  newSince,
  isLoggedIn,
  favorite,
  onFavoriteToggle,
  fallbackCartLanguage,
  filesizeBytes,
  ...props
}) {
  const [error, setError] = useState()
  const [downloadPopoverAnchor, setDownloadPopoverAnchor] = useState()
  const DocumentContent = (
    <>
      <Thumbnail>{thumbnail && !error ? <img alt='' src={thumbnail} onError={setError} /> : <PlaceholderImage />}</Thumbnail>
      <Shadow><img alt='' src={shadow} /></Shadow>
      <Title title={title && title.length > showTitleOnHoverAfterLength && title}>{title}</Title>
      {(props.isNew) &&
        <IndicatorNew>
          <BlueCircle />
          {i18n.t('search.new')}
        </IndicatorNew>}
    </>
  )

  const isPreviewable = props.youtubeId || browserSupportsFiletype(props.mimetype || '')

  const humanReadableFilesize = (!isPreviewable && filesizeBytes) ? formatAsLocalizeHumanReadableFilesize(filesizeBytes) : '' // filesize is only displayed for non-previewable documents

  return (
    <Container {...props}>
      <IconBar
        id={id}
        onCartToggle={onCartToggle}
        inCart={inCart}
        orderable={orderable}
        isLoggedIn={isLoggedIn}
        favorite={favorite}
        onFavoriteToggle={onFavoriteToggle}
        fallbackCartLanguage={fallbackCartLanguage}
      />
      {isPreviewable
        ? (
          <Link download={download} to={to}>
            {DocumentContent}
          </Link>
          )
        : (
          <div onClick={(event) => setDownloadPopoverAnchor(event.currentTarget)}>
            {DocumentContent}
          </div>
          )}
      <IconBar
        right
        copy
        id={id}
      />
      <Popover
        id={downloadPopoverAnchor ? id : undefined}
        open={!!downloadPopoverAnchor}
        anchorEl={downloadPopoverAnchor}
        onClose={() => setDownloadPopoverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <ModalContainer onClick={() => setDownloadPopoverAnchor(null)}>
          <PopoverTitle>{title}</PopoverTitle>
          <DownloadMessage>{i18n.t('documentFilter.downloadMessage')}</DownloadMessage>
          <Link download={download} to={to}>
            <CustomButton variant='large-shadow'>{i18n.t('documentFilter.download')}{humanReadableFilesize && ` (${humanReadableFilesize})`}</CustomButton>
          </Link>
        </ModalContainer>
      </Popover>
    </Container>
  )
}

Document.propTypes = {
  inCart: PropTypes.bool,
  orderable: PropTypes.bool,
  favorite: PropTypes.bool,
  onCartToggle: PropTypes.func,
  onFavoriteToggle: PropTypes.func,
  id: PropTypes.string,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
  download: PropTypes.bool
}
