import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom'

const Container = styled(Grid)`
  height: 64px;
  border-bottom: 1px solid #C6C1B7;
  background-color: #fff;
`

const BackContainer = styled(Grid)`
  padding-left: 20px;
`

const Title = styled(Grid)`
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  font-size: 16px;
  color: #333;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #333;
`

const Component = ({ language, document }) => {
  const { title } = document

  return (
    <>
      <Container container spacing={0} alignItems='center'>
        <BackContainer item xs={3}><StyledLink to={`/${language}`}><FontAwesomeIcon icon={faChevronLeft} /></StyledLink></BackContainer>
        <Title item xs={6}>{title ? title[language] : ''}</Title>
      </Container>
    </>
  )
}

export default Component
