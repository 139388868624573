import React from 'react'
import PropTypes from 'prop-types'
import LongTable from './LongTable'
import ShortTable from './ShortTable'

export default function FullTable ({ documents, short }) {
  if (short) {
    return <ShortTable documents={documents} />
  }

  return <LongTable documents={documents} />
}

FullTable.propTypes = {
  documents: PropTypes.array.isRequired,
  short: PropTypes.bool
}
