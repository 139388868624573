import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Toolbar from './Toolbar'

const Image = styled.img`
  max-width: 1024px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
`

const Error = styled.div`
  color: #933;
  text-align: center;
  margin-top: 16px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
`

export default function ImageViewer ({ language, document }) {
  const { title, downloadUrl } = document

  return (
    <>
      <Toolbar language={language} document={document} />
      {!downloadUrl && <Error>No Image configured</Error>}
      {!!downloadUrl && <Image alt={title} src={downloadUrl} />}
    </>
  )
}

ImageViewer.propTypes = {
  document: PropTypes.object
}
