const translation = {
  login: {
    headline: 'Informazioni su login/registrazione',
    infoText: "Per proteggere i Suoi dati personali, abbiamo modificato la gestione utenti del nostro portale Partner e delle relative applicazioni. Per questo è necessario <b>registrarsi nuovamente una sola volta</b> . A tale scopo occorrono l'indirizzo e-mail e il numero cliente. Dopo la registrazione, tutte le funzioni saranno disponibili come di consueto. Ha problemi con la registrazione o non ha ancora un account cliente? Nessun problema, ci invii una e-mail a <a href='mailto:marketing.chriw@assaabloy.com'>marketing.chriw@assaabloy.com<a/>.",
    button: 'Avanti per effettuare il login/la registrazione'
  },
  documentFilter: {
    productGroup: 'Gruppi di prodotti',
    brand: 'Marchi',
    topics: 'Temi',
    documentTypes: 'Tipi di documento',
    download: 'Scarica il documento',
    downloadMessage: 'Il documento selezionato non può essere visualizzato nel browser. \n\nVuoi scaricare il file?',
    search: 'Ricerca',
    favorites: 'Preferiti',
    eLearning: 'eLearning',
    cart: 'Carrello'
  },
  search: {
    noResultTitle: 'Spiacente, non sono stati trovati risultati',
    noResultText: 'Per espandere la tua ricerca, cambia o rimuovi i filtri',
    new: 'Nuovo'
  },
  navigation: {
    help: 'Guida',
    legalNotice: 'Impronta',
    contact: 'Contatto',
    logout: 'Logout',
    login: 'Login',
    profile: 'Profilo'
  },
  cart: {
    empty: 'Il carrello è vuoto',
    headline: 'I miei ordini',
    brochureOrder: 'Documento',
    releaseDate: 'Data di pubblicazione',
    amount: 'Quantità',
    sum: 'Totale',
    article: 'Articolo',
    order: 'Ordinare'
  },
  orderForm: {
    headline: "Modulo d'ordine",
    pleaseFillForm: "La preghiamo di compilare il modulo in ogni sua parte per completare l'ordine.",
    firmname: 'Ditta',
    salutation: 'Appellativo',
    male: 'Signor',
    female: 'Signora',
    name: 'Nome',
    surname: 'Cognome',
    street: 'Via n.',
    postalCode: 'Codice postale',
    city: 'Località',
    email: 'Indirizzo e-mail',
    phone: 'Numero di telefono',
    comment: 'Commento',
    dataProtectionConfirmation: "Sì, ho letto l'informativa sulla protezione dei dati personali e acconsento al trattamento dei miei dati.",
    dataProtection: 'Informativa sulla protezione dei dati personali',
    confirm: 'Inviare e completare il processo di ordinazione',
    orderFailed: "Impossibile inviare l'ordine, si prega di riprovare più tardi."
  },
  orderSuccess: {
    successHeadline: 'Ordine completato con successo',
    successUnderline: 'Grazie per il Suo ordine'
  },
  error: {
    imageViewer: 'Nessuna immagine configurata',
    videoViewer: 'Video YouTube non configurato correttamente'
  },
  sideBar: {
    german: 'Tedesco',
    english: 'Inglese',
    french: 'Francese',
    italian: 'Italiano'
  },
  links: {
    help: 'https://www.assaabloyopeningsolutions.ch/en/contact/',
    legalNotice: 'https://www.assaabloy.com/ch/en/imprint',
    contact: 'https://www.assaabloyopeningsolutions.ch/en/contact/',
    dataProtection: 'https://www.assaabloyopeningsolutions.ch/en/landingpage/terms-and-conditions-app/'
  },
  profile: {
    myProfile: 'Profilo personale',
    email: 'Indirizzo e-mail',
    customerId: 'Codice cliente',
    company: 'Azienda',
    firstname: 'Nome',
    lastname: 'Cognome',
    language: 'Lingua',
    de: 'Tedesco',
    en: 'Inglese',
    fr: 'Francese',
    it: 'Italiano',
    logout: 'Logout',
    edit: 'Modifica dati profilo',
    delete: 'Elimina account',
    save: 'Salva',
    successSave: 'Modifiche salvate con successo',
    deleteDescription: 'Sei sicuro di voler eliminare il tuo account?',
    errorSave: 'Si è verificato un errore durante il salvataggio. Riprovare più tardi.',
    errorDelete: 'Si è verificato un errore durante l\'eliminazione. Riprovare più tardi.',
    yes: 'Sì',
    ok: 'Ok',
    cancel: 'Cancellare'
  }

}

export default translation
