import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { decodeToken } from 'react-jwt'

const BACKEND_URL = process.env.REACT_APP_BACKEND || 'https://qr-backend-preview.asab.hpm.agency'

const useUser = (loginData) => {
  const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0()
  const [appUser, setAppUser] = useState({})

  useEffect(() => {
    const getToken = async () => {
      const claims = await getIdTokenClaims()
      let token
      if (claims) {
        token = claims.__raw
      }
      if (token) {
        const urlMetadata = 'https://qr-backend.asab.hpm.agency/user_metadata'
        const urlAppdata = 'https://qr-backend.asab.hpm.agency/app_metadata'
        const tokenInfoUser = decodeToken(token)
        tokenInfoUser.user_metadata = tokenInfoUser[urlMetadata]
        delete tokenInfoUser[urlMetadata]
        if (!appUser[urlAppdata]) {
          // make random call to identify user to backend
          await fetch(`${BACKEND_URL}/user/document/search`, {
            headers: {
              accept: '*/*',
              'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8,la;q=0.7',
              authorization: 'Token ' + token,
              'content-type': 'application/json',
              'sec-ch-ua': '" Not;A Brand";v="99", "Google Chrome";v="91", "Chromium";v="91"',
              'sec-ch-ua-mobile': '?0',
              'sec-fetch-dest': 'empty',
              'sec-fetch-mode': 'cors',
              'sec-fetch-site': 'cross-site'
            },
            referrer: 'http://localhost:3000/',
            referrerPolicy: 'strict-origin-when-cross-origin',
            body: null,
            method: 'GET',
            mode: 'cors'
          })

          // get new Token
          const claims = await getIdTokenClaims()
          let token2
          if (claims) {
            token2 = claims.__raw
          }
          const tokenInfoUser = decodeToken(token2)
          setAppUser({ ...appUser, ...tokenInfoUser, token: token2 })
        } else {
          setAppUser({ ...appUser, ...tokenInfoUser, token })
        }
      }
    }
    if (isAuthenticated && !isLoading && appUser && !appUser.token && appUser.email) {
      getToken()
    }
  }, [isAuthenticated, getIdTokenClaims, appUser, isLoading, user])

  useEffect(() => {
    if (isAuthenticated && !isLoading && !appUser.email && user && user.email) {
      setAppUser({ ...user })
    }
  }, [isAuthenticated, getIdTokenClaims, appUser, isLoading, user])

  return appUser
}

export default useUser
