import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import Config from './auth0Config'
import { config } from '@fortawesome/fontawesome-svg-core'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false

/**
 * CSP: Set a special variable to add `nonce` attributes to all styles/script tags.
 * The value is set in index.html and generated in nginx on request.
 * See https://github.com/webpack/webpack/pull/3210
 */
/* global __webpack_nonce__ */ // eslint-disable-line no-unused-vars
// webpack, styled-components, google tag manager
__webpack_nonce__ = window.__webpack_nonce__ // eslint-disable-line no-global-assign, camelcase
// material-ui styles through emotion
const cache = createCache({
  key: 'css',
  nonce: window.__webpack_nonce__
})
const EmotionStyleCacheContainer = ({ children, ...restProps }) => {
  return (
    <CacheProvider value={cache}>
      {children}
    </CacheProvider>
  )
}

const root = createRoot(document.getElementById('root'))
root.render(
  <EmotionStyleCacheContainer>
    <Auth0Provider
      domain={Config.domain}
      clientId={Config.clientId}
      redirectUri={Config.redirectUri}
      scope={Config.scope}
      responseType={Config.responseType}
      grant_type={Config.grant_type}
    >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Auth0Provider>
  </EmotionStyleCacheContainer>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
