import React from 'react'
import Image from './Image'
import Video from './Video'

const Component = ({ language, document }) => {
  if (!document) {
    return <>Document missing</>
  }

  if (document.youtubeId) {
    return <Video language={language} document={document} />
  }

  if (document.mimetype && document.mimetype.includes('image/')) {
    return <Image language={language} document={document} />
  }

  return <>Document Type {document.mimetype} not supported</>
}

export { Image, Video }

export default Component
