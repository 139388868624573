import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import styled from 'styled-components'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TextField from '@material-ui/core/TextField'

const Thumbnail = styled.div`
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  & img {
    border: 1px solid #dbdbdb;
    max-width: 63px;
    max-height: 51px;
  }
`

const Title = styled.div`
  font-size: 14px;
  margin-left: 20px;
`

const TitleCell = styled.div`
  display: flex;
  align-items: center;
`

const RemoveIcon = styled(FontAwesomeIcon)`
  color: #A2A2A2;
  cursor: pointer;
`

const AmountCell = styled(TableCell)`
  width: 100px;
`

const handleChange = (event) => {
  let croppedValue = event.target.value
  if (!croppedValue || croppedValue < 1) {
    croppedValue = 1
    selectOnOne(event.target)
  }
  if (croppedValue > 100) {
    croppedValue = 100
  }
  return croppedValue
}

const preventInput = (event) => {
  const prevent = ['.', ',', '+', '-', 'e', 'E']
  prevent.includes(event.key) && event.preventDefault()
}

const selectOnOne = (target) => setTimeout(() => target.select(), 50)

const Component = ({ id, thumbnail, title, publishedAt, cartAmount, onCartToggle, onChangeAmount }) => {
  return (
    <TableRow>
      <TableCell>
        <TitleCell>
          <Thumbnail><img alt='' src={thumbnail} /></Thumbnail>
          <Title>{title}</Title>
        </TitleCell>
      </TableCell>
      <TableCell>{publishedAt.toLocaleDateString()}</TableCell>
      <AmountCell align='right'>
        <FormControl fullWidth>
          <InputLabel />
          <TextField
            value={cartAmount}
            type='number'
            onFocus={(event) => event.target.select()}
            style={{
              maxWidth: '70%'
            }}
            inputProps={{ style: { paddingLeft: 5 } }}
            onChange={(event) => {
              if (!onChangeAmount) {
                return
              }
              onChangeAmount(id, parseInt(handleChange(event)))
            }}
            onKeyDown={preventInput}
          />
        </FormControl>
      </AmountCell>
      <TableCell><RemoveIcon
        icon={faTimes} onClick={() => {
          if (!onCartToggle) {
            return
          }
          onCartToggle(id)
        }}
                 />
      </TableCell>
    </TableRow>
  )
}

export default Component
