const translation = {
  login: {
    headline: 'Information on login/registration',
    infoText: 'In order to protect your personal data, we have changed the user management of our partner portal and the associated applications. It is therefore necessary to register once again. To do this, you need your e-mail address and your customer number. After registration, all functions will be available to you as usual. If you have any questions, please contact marketing.chriw@assaabloy.com',
    button: 'Proceed to login/registration'
  },
  documentFilter: {
    productGroup: 'Product groups',
    brand: 'Brands',
    topics: 'Topics',
    documentTypes: 'Document types',
    download: 'Download document',
    downloadMessage: 'The selected document can not be previewed in the browser. \n\nWould you like to download the file?',
    search: 'Search',
    favorites: 'Favorites',
    eLearning: 'eLearning',
    cart: 'Cart'
  },
  search: {
    noResultTitle: 'Sorry, no results were found',
    noResultText: 'To expand your search, change or remove the filters',
    new: 'New'
  },
  navigation: {
    help: 'Help',
    legalNotice: 'Legal notice',
    contact: 'Contact',
    logout: 'Logout',
    login: 'Login',
    profile: 'Profile'
  },
  cart: {
    empty: 'Your shopping cart is empty',
    headline: 'My orders',
    brochureOrder: 'Document',
    releaseDate: 'Publication date',
    amount: 'Quantity',
    sum: 'Total',
    article: 'Item',
    order: 'Order'
  },
  orderForm: {
    headline: 'Order form',
    pleaseFillForm: 'Please fill in the form completely to complete the order.',
    firmname: 'Company',
    salutation: 'Salutation',
    male: 'Mr',
    female: 'Ms',
    name: 'Name',
    surname: 'Surname',
    street: 'Street no.',
    postalCode: 'Postcode',
    city: 'Place',
    email: 'Email address',
    phone: 'Telephone number',
    comment: 'Comment',
    dataProtectionConfirmation: 'Yes, I have read the privacy policy and agree to the processing of my information.',
    dataProtection: 'Privacy policy',
    confirm: 'Send and complete order process',
    orderFailed: 'Order failed to send, please try again later.'
  },
  orderSuccess: {
    successHeadline: 'Order completed successfully',
    successUnderline: 'Thank you for your order'
  },
  error: {
    imageViewer: 'No image configured',
    videoViewer: 'YouTube video not configured correctly'
  },
  sideBar: {
    german: 'German',
    english: 'English',
    french: 'French',
    italian: 'Italian'
  },
  links: {
    help: 'https://www.assaabloyopeningsolutions.ch/en/contact/',
    legalNotice: 'https://www.assaabloy.com/ch/en/imprint',
    contact: 'https://www.assaabloyopeningsolutions.ch/en/contact/',
    dataProtection: 'https://www.assaabloyopeningsolutions.ch/en/landingpage/terms-and-conditions-app/'
  },
  profile: {
    myProfile: 'My profile',
    email: 'Email address',
    customerId: 'Customer number',
    company: 'Company',
    firstname: 'First name',
    lastname: 'Surname',
    language: 'Language',
    de: 'German',
    en: 'English',
    fr: 'French',
    it: 'Italian',
    logout: 'Log out',
    edit: 'Edit profile data',
    delete: 'Delete account',
    save: 'Save',
    successSave: 'Changes saved successfully',
    deleteDescription: 'Are you sure you want to delete your account?',
    errorSave: 'An error occurred while saving. Please try again later',
    errorDelete: 'An error occurred during deletion. Please try again later',
    yes: 'Yes',
    ok: 'Ok',
    cancel: 'Cancel'
  }

}

export default translation
