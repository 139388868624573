import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem } from '@material-ui/core'
import styled from 'styled-components'

const Title = styled.div`
  font-size: 14px;
  min-width: 128px;
  width: 90%;
  padding-left: 16px;

  &.fullWidth {
    width: 100%;
  }
`

const SelectedIndicator = styled.div`
  padding-left: 16px;
  width: 10%;
  font-size: 14px;
  display: inline-grid;
  text-align: right;
`

const StyledSelect = styled(Select)`
  margin-right: 16px;
  background-color: #fff;
  height: 32px;
`

export default function Filter ({ title, active, options, onFilter, ...props }) {
  const [selectedOptions, setSelectedOptions] = useState(active || [])

  const selectOption = (event) => {
    setSelectedOptions(event.target.value)
  }

  useEffect(() => {
    if (onFilter) {
      onFilter(selectedOptions)
    }
  }, [selectedOptions, onFilter])

  useEffect(() => {
    if (!active) {
      return
    }
    setSelectedOptions(active)
  }, [active])

  return (
    <StyledSelect
      variant='outlined'
      value={selectedOptions}
      onChange={selectOption}
      renderValue={() => <Title className='fullWidth'>{title}</Title>}
      displayEmpty
      multiple
    >
      {options.map(({ id, title }) => (
        <MenuItem key={id} value={id}>
          <Title>{title}</Title>
          <SelectedIndicator>{selectedOptions && selectedOptions.includes(id) ? '✓' : ''}</SelectedIndicator>
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

Filter.propTypes = {
  options: PropTypes.array,
  active: PropTypes.array,
  title: PropTypes.string,
  onFilter: PropTypes.func
}
