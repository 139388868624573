import { useState, useEffect, useCallback } from 'react'
import fetchAsync from './fetchAsync'
import { mapDocument } from './documentMapper'
import { useAuth0 } from '@auth0/auth0-react'

const BACKEND_URL = process.env.REACT_APP_BACKEND || 'https://qr-backend-preview.asab.hpm.agency'

const cache = {}

const useDocuments = (user) => {
  const [documents, setDocuments] = useState([])
  const [documentsFavorite, setDocumentsFavorite] = useState([])
  const [rawDocuments, setRawDocuments] = useState([])
  const [rawFavoriteDocuments, setRawFavoriteDocuments] = useState([])
  const [loading, setLoading] = useState(false)
  const url = `${BACKEND_URL}/v2/users/documents`

  const { isAuthenticated, isLoading } = useAuth0()
  const loadDocuments = useCallback(async (url, user) => {
    if (!url) {
      return
    }
    // @TODO reeanable cache if performance demands it, test favorite functionality carfully
    // if (cache[url] && cache[url].length) {
    //   return setRawDocuments(cache[url])
    // }
    setLoading(true)
    if (!user) {
      const { documents } = await fetchAsync(url)
      if (documents) {
        documents.forEach(document => { document.id = document._id })
      }
      cache[url] = documents

      setRawDocuments(cache[url])
      setLoading(false)
    } else {
      const { documents } = await fetchAsync(url, { token: user.token })
      if (documents) {
        documents.forEach(document => { document.id = document._id })
      }
      cache[url] = documents

      const res = await fetchAsync(`${url}/?favorites=true`, { token: user.token })
      const favoriteDocuments = res.documents
      if (favoriteDocuments) {
        favoriteDocuments.forEach(document => { document.id = document._id })
      }

      setRawFavoriteDocuments(favoriteDocuments)
      setRawDocuments(cache[url])
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!user || !user.token) {
      setRawDocuments([])
      setRawFavoriteDocuments([])
    }
  }, [user])

  useEffect(() => {
    if (user && user.token) {
      loadDocuments(url, user)
    }
  }, [user, loadDocuments, url])

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loadDocuments(url)
    }
  }, [isLoading, isAuthenticated, loadDocuments, url])

  useEffect(() => {
    if (rawDocuments) {
      const filteredDocuments = rawDocuments.map(document => mapDocument(document))
      setDocuments(filteredDocuments)
    }
  }, [rawDocuments])

  useEffect(() => {
    if (rawFavoriteDocuments) {
      const filteredDocuments = rawFavoriteDocuments.map(document => mapDocument(document))
      setDocumentsFavorite(filteredDocuments)
    }
  }, [rawFavoriteDocuments])

  function isFavorite (documents, id) {
    return documents.some(item => item.id === id && item.favorite)
  }

  function setFavorite (documents, id) {
    const pushDocuments = []
    setRawDocuments(documents.map((document) => {
      if (document.id === id) {
        document.favorite = !document.favorite
        if (document.favorite) {
          pushDocuments.push(document)
        }
      }
      return document
    }))

    setRawFavoriteDocuments((rawFavoriteDocuments.map((document) => {
      if (document.id === id) {
        document.favorite = !document.favorite
      }
      return document
    })).concat(pushDocuments))
  }

  const handleFavorite = async (id) => {
    const favoriteUrl = `${BACKEND_URL}/users/${user.sub}/favorites/${id}`
    const method = isFavorite(rawDocuments, id) ? 'DELETE' : 'PUT'
    await fetchAsync(favoriteUrl, { token: user.token, method })
    if (method === 'PUT') {
      setFavorite(rawDocuments, id)
    }
    if (method === 'DELETE') {
      loadDocuments(url, user)
    }
  }

  return { documents, documentsFavorite, loading, handleFavorite }
}

export default useDocuments
