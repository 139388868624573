const fetchAsync = async (url, params) => {
  const controller = new window.AbortController()
  const { signal } = controller

  const fetchParams = {
    ...params,
    signal // extend provided params with AbortController signal
  }

  if (fetchParams.token) {
    const data = await window.fetch(url, {
      headers: {
        authorization: `Token ${fetchParams.token}`
      },
      method: fetchParams.method || 'GET'
    })
    const json = await data.json()
    return json
  } else {
    const data = await window.fetch(url, fetchParams)
    const json = await data.json()
    return json
  }
}

export default fetchAsync
