const translation = {
  login: {
    headline: 'Informations pour s‘inscrire/s‘enregistrer',
    infoText: 'Fin de protéger vos données personnelles, nous avons modifié la gestion des utilisateurs de notre portail partenaires et des applications associées. C’est pourquoi il est nécessaire de procéder une seule fois à votre réinscription. Pour cela, vous aurez besoin de votre adresse e-mail et de votre numéro de client. Toutes les fonctionnalités habituelles seront à votre disposition après votre inscription. Si vous avez des questions, veuillez contacter marketing.chriw@assaabloy.com',
    button: "Poursuivre vers la procédure d'inscription/d'enregistrement"
  },
  documentFilter: {
    productGroup: 'Groupes de produits',
    brand: 'Marque',
    topics: 'Sujets',
    documentTypes: 'Types de documents',
    download: 'Télécharger le document',
    downloadMessage: 'Le document sélectionné ne peut pas être affiché dans le navigateur. \n\nVoulez-vous télécharger le fichier?',
    search: 'Recherchez',
    favorites: 'Favoris',
    eLearning: 'eLearning',
    cart: 'Panier'
  },
  search: {
    noResultTitle: "Malheureusement, aucun résultat n'a été trouvé",
    noResultText: 'Pour élargir votre recherche, modifier ou supprimer les filtres',
    new: 'Nouveau'
  },
  navigation: {
    help: 'Aide',
    legalNotice: 'Mentions légales',
    contact: 'Contactez',
    logout: 'Se déconnecter',
    login: 'Connexion',
    profile: 'Profil'
  },
  cart: {
    empty: 'Votre panier est vide',
    headline: 'Mes commandes',
    brochureOrder: 'Document',
    releaseDate: 'Date de publication',
    amount: 'Quantité',
    sum: 'Total',
    article: 'Article',
    order: 'Commander'
  },
  orderForm: {
    headline: 'Formulaire de commande',
    pleaseFillForm: 'Veuillez compléter le formulaire intégralement pour terminer la commande.',
    firmname: 'Entreprise',
    salutation: 'Civilité',
    male: 'Monsieur',
    female: 'Madame',
    name: 'Nom',
    surname: 'Prénom',
    street: 'N° de rue',
    postalCode: 'Code postal',
    city: 'Ville',
    email: 'Adresse électronique',
    phone: 'Numéro de téléphone',
    comment: 'Commentaire',
    dataProtectionConfirmation: 'Oui, j’ai lu la Politique de confidentialité et j’accepte le traitement de mes données.',
    dataProtection: 'Politique de protection des données',
    confirm: 'Envoyer et terminer le processus de commande',
    orderFailed: 'Échec d’envoi de la commande, veuillez réessayer plus tard.'
  },
  orderSuccess: {
    successHeadline: 'Commande passée avec succès',
    successUnderline: 'Nous vous remercions pour votre commande'
  },
  error: {
    order: 'Échec d’envoi de la commande, veuillez réessayer plus tard.',
    imageViewer: 'Aucune image configurée',
    videoViewer: 'Vidéo YouTube pas configurée correctement'
  },
  sideBar: {
    german: 'Allemand',
    english: 'Anglais',
    french: 'Français',
    italian: 'Italien'
  },
  links: {
    help: 'https://www.assaabloyopeningsolutions.ch/fr/contact1/',
    legalNotice: 'https://www.assaabloy.com/ch/fr/imprint',
    contact: 'https://www.assaabloyopeningsolutions.ch/fr/contact1/',
    dataProtection: 'https://www.assaabloyopeningsolutions.ch/fr/landingpage/conditions-dutilisation/'
  },
  profile: {
    myProfile: 'Mon profil',
    email: 'Adresse électronique',
    customerId: 'Numéro client',
    company: 'Société',
    firstname: 'Prénom',
    lastname: 'Nom',
    language: 'Langue',
    de: 'Allemand',
    en: 'Anglais',
    fr: 'Français',
    it: 'Italien',
    logout: 'Se déconnecter',
    edit: 'Modifier les données de profil',
    delete: 'Supprimer le compte',
    save: 'Enregistrer',
    successSave: 'Modifications enregistrées avec succès',
    deleteDescription: 'Êtes-vous sûr(e) de vouloir supprimer votre compte?',
    errorSave: 'Une erreur est survenue lors de l’enregistrement. Veuillez réessayer ultérieurement.',
    errorDelete: 'Une erreur est survenue lors de la suppression. Veuillez réessayer ultérieurement.',
    yes: 'Oui',
    cancel: 'Annuler',
    ok: 'Ok'
  }

}

export default translation
