const translation = {
  login: {
    headline: 'Information zum Anmelden/Registrieren',
    infoText: 'Zum Schutz Ihrer persönlichen Daten haben wir die Nutzerverwaltung unseres Partnerportals und der damit verbundenen Applikationen umgestellt. Deshalb ist es notwendig, dass Sie sich <b>einmalig neu registrieren</b>. Dazu benötigen Sie Ihre E-Mail-Adresse und Ihre Kundennummer. Nach der Registrierung stehen Ihnen alle Funktionen wie gewohnt zur Verfügung. Haben Sie Probleme bei der Registrierung oder haben noch keinen Kunden-Account? Kein Problem, senden Sie uns eine E-Mail an <a href="mailto:marketing.chriw@assaabloy.com">marketing.chriw@assaabloy.com<a/>.',
    button: 'Weiter zum Anmelden/Registrieren'
  },
  documentFilter: {
    productGroup: 'Produktgruppen',
    brand: 'Marken',
    topics: 'Themen',
    documentTypes: 'Dokumentarten',
    download: 'Dokument herunterladen',
    downloadMessage: 'Das ausgewählte Dokument kann nicht im Browser dargestellt werden. \n\nMöchten Sie die Datei herunterladen?',
    search: 'Suche',
    favorites: 'Favoriten',
    eLearning: 'eLearning',
    cart: 'Warenkorb'
  },
  search: {
    noResultTitle: 'Leider wurden keine Ergebnisse gefunden',
    noResultText: 'Um Ihre Suche zu erweitern, ändern oder entfernen Sie die Filter',
    new: 'Neu'
  },
  navigation: {
    help: 'Hilfe',
    legalNotice: 'Impressum',
    contact: 'Kontakt',
    logout: 'Abmelden',
    login: 'Anmelden',
    profile: 'Profil'
  },
  cart: {
    empty: 'Ihr Warenkorb ist leer',
    headline: 'Meine Bestellungen',
    brochureOrder: 'Dokument',
    releaseDate: 'Veröffentlichungsdatum',
    amount: 'Menge',
    sum: 'Summe',
    article: 'Artikel',
    order: 'Bestellen'
  },
  orderForm: {
    headline: 'Bestellformular',
    pleaseFillForm: 'Bitte füllen Sie das Formular vollständig aus, um die Bestellung abzuschließen.',
    firmname: 'Firma',
    salutation: 'Anrede',
    male: 'Herr',
    female: 'Frau',
    name: 'Name',
    surname: 'Nachname',
    street: 'Straße Nr.',
    postalCode: 'Postleitzahl',
    city: 'Ort',
    email: 'E-Mail-Adresse',
    phone: 'Telefonnummer',
    comment: 'Kommentar',
    dataProtectionConfirmation: 'Ja, ich habe die Datenschutzerklärung gelesen und stimme der Verarbeitung meiner Daten zu.',
    dataProtection: 'Datenschutzerklärung',
    confirm: 'Senden und Bestellvorgang abschließen',
    orderFailed: 'Senden der Bestellung fehlgeschlagen, bitte versuchen Sie es später erneut.'
  },
  orderSuccess: {
    successHeadline: 'Bestellung erfolgreich abgeschlossen',
    successUnderline: 'Vielen Dank für Ihre Bestellung'
  },
  error: {
    imageViewer: 'Kein Bild konfiguriert',
    videoViewer: 'YouTube Video nicht korrekt konfiguriert'
  },
  sideBar: {
    german: 'Deutsch',
    english: 'Englisch',
    french: 'Französisch',
    italian: 'Italienisch'
  },
  links: {
    help: 'https://www.assaabloyopeningsolutions.ch/de/kontakt/',
    legalNotice: 'https://www.assaabloy.com/ch/de/impressum',
    contact: 'https://www.assaabloyopeningsolutions.ch/de/kontakt/',
    dataProtection: 'https://www.assaabloyopeningsolutions.ch/de/landingpage/datenschutzerklarung-app'
  },
  profile: {
    myProfile: 'Mein Profil',
    email: 'E-Mail-Adresse',
    customerId: 'Kundennummer',
    company: 'Firma',
    firstname: 'Vorname',
    lastname: 'Nachname',
    language: 'Sprache',
    de: 'Deutsch',
    en: 'Englisch',
    fr: 'Französisch',
    it: 'Italienisch',
    logout: 'Abmelden',
    edit: 'Profildaten bearbeiten',
    delete: 'Account löschen',
    save: 'Speichern',
    successSave: 'Änderungen erfolgreich gespeichert',
    deleteDescription: 'Sind Sie sich sicher, dass Sie Ihren Account löschen möchten?',
    errorSave: 'Es ist ein Fehler beim Speichern aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
    errorDelete: 'Es ist ein Fehler beim Löschen aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
    yes: 'Ja',
    ok: 'Ok',
    cancel: 'Abbrechen'
  }
}

export default translation
