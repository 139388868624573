import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'

import Button from '../common/Button'
import i18n from '../../i18n'

const InputRow = styled(Grid)`
  margin-bottom:20px !important;
`

const SubmitRow = styled(Grid)`
  margin-bottom:20px !important;
`

const PrivacyContainer = styled(Grid)`
  margin-bottom:20px !important;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.23);
  height: 90px;
  padding: 19px 26px 0 26px;
`
const PrivacyLink = styled.a`
  margin-left:28px !important;
`

const Textarea = styled.textarea`
  overflow-y: scroll;
  height: 150px;
  font-size: 1rem;
  width: 99.2%;
  resize: none;
  padding-top: 10px;
  padding-left: 10px;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 1rem;
  }
  :-ms-input-placeholder {
    font-size: 1rem;
  }
`

const salutations = [
  'male',
  'female'
]

const requiredFields = ['firstname', 'lastname', 'street', 'zip', 'city', 'email', 'phone', 'privacy']

const Component = ({ privacyError, onSubmit }) => {
  const [formData, setFormData] = useState({})
  const [missingData, setMissingData] = useState(true)

  const handleDataChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value })
  }

  const handleToggleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.checked })
  }

  useEffect(() => {
    const missingField = requiredFields.find(field => !formData[field])
    setMissingData(!!missingField)
  }, [formData])

  const handleSubmit = async () => {
    if (!missingData && onSubmit) {
      onSubmit(formData)
    }
  }

  return (
    <>
      <InputRow container direction='row' spacing={1}>
        <Grid item xs={6}><TextField onChange={handleDataChange('companyname')} fullWidth label={i18n.t('orderForm.firmname')} variant='outlined' /></Grid>
      </InputRow>
      <InputRow container direction='row' spacing={1}>
        <Grid item xs={6}>
          <TextField select fullWidth label={i18n.t('orderForm.salutation')} variant='outlined' onChange={handleDataChange('salutation')}>
            {salutations.map((option) => (
              <MenuItem key={option} value={option}>
                {i18n.t(`orderForm.${option}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}><TextField onChange={handleDataChange('firstname')} required fullWidth label={i18n.t('orderForm.name')} variant='outlined' /></Grid>
      </InputRow>
      <InputRow container direction='row' spacing={1}>
        <Grid item xs={6}><TextField onChange={handleDataChange('lastname')} required fullWidth label={i18n.t('orderForm.surname')} variant='outlined' /></Grid>
        <Grid item xs={6}><TextField onChange={handleDataChange('street')} required fullWidth label={i18n.t('orderForm.street')} variant='outlined' /></Grid>
      </InputRow>
      <InputRow container direction='row' spacing={1}>
        <Grid item xs={6}><TextField onChange={handleDataChange('zip')} required fullWidth label={i18n.t('orderForm.postalCode')} variant='outlined' /></Grid>
        <Grid item xs={6}><TextField onChange={handleDataChange('city')} required fullWidth label={i18n.t('orderForm.city')} variant='outlined' /></Grid>
      </InputRow>
      <InputRow container direction='row' spacing={1}>
        <Grid item xs={6}><TextField onChange={handleDataChange('email')} required fullWidth label={i18n.t('orderForm.email')} variant='outlined' /></Grid>
        <Grid item xs={6}><TextField onChange={handleDataChange('phone')} required fullWidth label={i18n.t('orderForm.phone')} variant='outlined' /></Grid>
      </InputRow>
      <InputRow container direction='row' spacing={1}>
        <Grid item xs={12}>
          <Textarea placeholder={i18n.t('orderForm.comment')} onChange={handleDataChange('comment')} />
        </Grid>
      </InputRow>
      <InputRow container direction='row' spacing={1}>
        <Grid item xs={12}>
          <PrivacyContainer>
            <FormControl component='fieldset' required>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox onChange={handleToggleChange('privacy')} value='Privacy statement accepted' color='primary' />
                  }
                  label={i18n.t('orderForm.dataProtectionConfirmation')}
                />
              </FormGroup>
            </FormControl>
            <br />
            <PrivacyLink href={i18n.t('links.dataProtection')} target='_blank'>{i18n.t('orderForm.dataProtection')}</PrivacyLink>
          </PrivacyContainer>
        </Grid>
      </InputRow>
      <SubmitRow item xs={12}>
        <Button variant='large-shadow' disabled={missingData} onClick={handleSubmit}>{i18n.t('orderForm.confirm')}</Button>
      </SubmitRow>

    </>
  )
}

export default Component
