import { I18n } from 'i18n-js'

import de from './de'
import en from './en'
import fr from './fr'
import it from './it'

const SUPPORTED_LANGUAGES = ['de', 'en', 'fr', 'it']
const translations = { en, de, fr, it }
const i18n = new I18n(translations)

const getLanguage = () => {
  let userLang = navigator.language || navigator.userLanguage

  if (userLang.indexOf('-') > -1) {
    userLang = userLang.substring(0, userLang.indexOf('-'))
  }

  if (!translations[userLang] || !SUPPORTED_LANGUAGES.includes(userLang)) {
    userLang = 'en'
  }

  return userLang
}

i18n.locale = getLanguage()
i18n.defaultLocale = 'en'
i18n.fallbacks = true

export default i18n

export { SUPPORTED_LANGUAGES, getLanguage }
