import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'

const InternalLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
const ExternalLink = styled.a`
  color: inherit;
  text-decoration: none;
`

const documentIdRegex = /documents\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})\/download/
const sendDocumentOpenAnalytics = (uri) => {
  const capture = documentIdRegex.exec(uri)
  if (!capture || !capture[1]) {
    return
  }
  const documentId = capture[1]
  try {
    ReactGA.event({
      category: 'Document',
      action: 'Open',
      label: String(documentId)
    })
  } catch (error) {
    console.error(`Could not send analytics event ${error.message}`)
  }
}

const Component = ({ to, download, children, ...props }) => {
  if (to.includes('://')) {
    return <ExternalLink download={download} href={to} target='_blank' onClick={() => sendDocumentOpenAnalytics(to)} {...props}>{children}</ExternalLink>
  }

  return <InternalLink to={to} {...props}>{children}</InternalLink>
}

export default Component
