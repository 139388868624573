import React, { useState, useContext, useEffect, useCallback, createContext } from 'react'
import { useFilteredDocuments } from './backend'
import { isExpired } from 'react-jwt'
import { useAuth0 } from '@auth0/auth0-react'
import useUser from './backend/useUser'

export const AppContext = createContext({
})
export const useApp = () => useContext(AppContext)

export const AppProvider = ({
  children
}) => {
  const [loginData, setLoginData] = useState({})
  // const [appUser, setAppUser] = usePersistentUser({})

  const { logout } = useAuth0()
  const appUser = useUser()

  const {
    loading,
    documents,
    documentsAll,
    handleFavorite: toggleFavorite,
    filterText,
    setFilterText,
    availableLanguages,
    filterLanguages,
    setFilterLanguages,
    availableProductGroups,
    availableBrands,
    availableTopics,
    availableTypes,
    filterProductGroups,
    filterTopics,
    filterBrands,
    filterTypes,
    setFilterProductGroups,
    setFilterTopics,
    setFilterBrands,
    setFilterTypes,
    documentsFavorite
  } = useFilteredDocuments(appUser)

  const [cartDocumentIds, setCartDocumentIds] = useState([])
  const [documentsWithCartInformation, setDocumentsWithCartInformation] = useState([])
  const [documentsFavoriteWithCartInformation, setDocumentsFavoriteWithCartInformation] = useState([])
  const [cartAmount, setDocumentCartAmount] = useState({})
  const [cartLanguage, setDocumentCartLanguage] = useState({})

  const logoutExpiredSession = useCallback(async () => {
    if (!appUser || !appUser.token) {
      return
    }
    const isTokenExpired = isExpired(appUser.token)
    if (isTokenExpired) {
      logout({ returnTo: window.location.origin })
    }
  }, [appUser, logout])

  useEffect(() => {
    logoutExpiredSession()
  }, [logoutExpiredSession, appUser])

  useEffect(() => {
    setDocumentsWithCartInformation(documents.map(document => {
      return {
        ...document,
        inCart: cartDocumentIds.includes(document.id),
        cartAmount: cartAmount[document.id] || 0,
        cartLanguage: cartLanguage[document.id] || 'de'
      }
    }))

    setDocumentsFavoriteWithCartInformation(documentsFavorite.map(document => {
      return {
        ...document,
        inCart: cartDocumentIds.includes(document.id),
        cartAmount: cartAmount[document.id] || 0,
        cartLanguage: cartLanguage[document.id] || 'de'
      }
    }))
  }, [cartDocumentIds, cartAmount, cartLanguage, documents, documentsAll, documentsFavorite])

  const toggleCart = async (id, language) => {
    if (cartDocumentIds.includes(id)) {
      setCartDocumentIds(cartDocumentIds.filter(documentId => id !== documentId))
      const documentCartLanguage = cartLanguage
      delete documentCartLanguage[id]
      setDocumentCartLanguage(documentCartLanguage)
    } else {
      setCartDocumentIds([id].concat(cartDocumentIds))
      setDocumentCartAmount({
        ...cartAmount,
        [id]: 1
      })
      setDocumentCartLanguage({
        ...cartLanguage,
        [id]: language
      })
    }
  }

  const setCartAmount = async (id, amount) => {
    if (!cartDocumentIds.includes(id)) {
      return
    }

    setDocumentCartAmount({
      ...cartAmount,
      [id]: amount
    })
  }

  const resetCart = () => {
    setDocumentCartAmount({})
    setDocumentCartLanguage({})
    setCartDocumentIds([])
  }

  return (
    <AppContext.Provider
      value={{
        loading,
        appUser,
        logout,
        loginData,
        setLoginData,
        documents: documentsWithCartInformation,
        cartDocumentIds,
        cartLanguage,
        documentsAll,
        documentsFavorite: documentsFavoriteWithCartInformation,
        cartAmount,
        availableLanguages,
        filterLanguages,
        setFilterLanguages,

        availableProductGroups,
        availableBrands,
        availableTopics,
        availableTypes,

        setFilterProductGroups,
        setFilterTopics,
        setFilterBrands,
        setFilterTypes,

        filterProductGroups,
        filterTopics,
        filterBrands,
        filterTypes,

        filterText,
        setFilterText,

        toggleFavorite,

        toggleCart,
        setCartAmount,
        resetCart
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const hook = { AppProvider, useApp }

export default hook
